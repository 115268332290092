import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { DateInput, Loader, ValidatedInput } from '../../components';
import { EditSVG } from '../../components/svgs';
import BackButton from '../../components/back-button/BackButton';
import FilterDropdown, { FilterOption, defaultFilterOption } from '../../components/custom-input/FilterDropdown';
import TransactionLabelField from '../../components/transaction/TransactionLabelField';
import ListStorageLocations from '../inventory-management/list-storage-locations/ListStorageLocations';
import { CollectDepositSelectedStates } from '../inventory-management/InventoryManagementCollectDeposit';
import { getCustomDataKeys } from '../../api/customDataPoints.api';
import { getSpecimen, getSpecimenOwnerByAccountId, getSpecimenOwners, updateSpecimen } from '../../api/specimensApi';
import { getSpecimenTypes } from '../../api/specimensTypesApi';
import { getStorageSites } from '../../api/storageItemsApi';
import { getUser } from '../../api/userApi';
import { clampValue } from '../../utils/commonUtils';
import { hashCode } from '../../utils/commonUtils';
import { cleanCustomData, handleCustomDataStateChange } from '../../services/customData.service';
import { requestRsgUsers } from '../../services/user.service';
import { showToast } from '../../services/toast.service';
import { Account, SpecimenInventoryWithAccount, SpecimenType, StorageSite, UserInfo } from '../../types/interfaces';
import { SpecimenCustomDataValue, SpecimenCustomDataKey } from '../../types/interfaces/specimenCustomDataKeyModel.interfaces';
import { QualityType, Validators, specimenUOM } from '../../types/enums';
import { ANIMAL_CONSTANTS, BUTTON_CONSTANTS, INVENTORY_CONSTANTS, LABEL_CONSTANTS } from '../../constants/common';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';

const SpecimenViewEdit: FC<{ itemId?: number }> = ({ itemId }): JSX.Element => {
  const { specimenId: paramSpecId } = useParams();
  const navigate = useNavigate();

  const currentLocation: string = location.pathname;

  const [specimenId, setSpecimenId] = useState<string>(itemId?.toString() ?? paramSpecId ?? '');
  const [selectedStates, setSelectedStates] = useState<CollectDepositSelectedStates>({
    storageLocations: [
      {
        site: { name: '', value: '' },
        tank: { name: '', value: '' },
        canister: { name: '', value: '' },
        quantity: { value: 0 },
        id: hashCode(new Date().toString()),
      },
    ],
  });

  const [freezeLocations, setFreezeLocations] = useState<FilterOption<StorageSite>[]>([]);
  const [initialSpecimenType, setInitialSpecimenType] = useState<{ name: string; value: SpecimenType }>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingCustomDataPoints, setIsLoadingCustomDataPoints] = useState<boolean>(true);
  const [rsgUsers, setRsgUsers] = useState<FilterOption<UserInfo>[]>([]);
  const [specimenOwners, setSpecimenOwners] = useState<SpecimenInventoryWithAccount[]>();
  const [specimensType, setSpecimensType] = useState<FilterOption<SpecimenType>[]>([]);
  const [updatingSpecimen, setUpdatingSpecimen] = useState<boolean>(true);

  const handlePageLoad = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data: reqItemType } = await getSpecimenTypes();
      const { data: reqSiteLocations } = await getStorageSites();
      const { data: reqRsgUsers } = await requestRsgUsers();

      const mappedItemType = reqItemType.map((item: SpecimenType) => ({ name: item.name ?? '', value: item }));

      const mappedSiteLocations = (reqSiteLocations as StorageSite[]).map((item: StorageSite) => {
        return { name: item.name ?? '', value: item };
      });

      const mappedRsgUsers = reqRsgUsers
        .filter(user => user.firstName || user.lastName)
        .map(item => ({ name: `${item.firstName ?? 'NA'} ${item.lastName ?? 'NA'}`, value: item }));

      await getSpecimenAccounts();
      setSpecimensType(mappedItemType);
      setFreezeLocations(mappedSiteLocations);
      setRsgUsers(mappedRsgUsers);
      setIsLoading(false);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, []);

  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  const getSpecimenAccounts = async () => {
    const { data: specimenAccounts } = await getSpecimenOwners(specimenId);

    const specimenOwners = await Promise.all(
      specimenAccounts.map(async (account: Account) => {
        const { data: specimenAccountResponse } = await getSpecimenOwnerByAccountId(specimenId, account.accountId!);
        specimenAccountResponse[0].account = account;
        return specimenAccountResponse[0];
      }),
    );

    setSpecimenOwners(specimenOwners);
  };

  const getSpec = async () => {
    try {
      setIsLoadingCustomDataPoints(true);

      if (specimenId.length > 0) {
        const { data: spec } = await getSpecimen(+specimenId, {
          include: 'Animal,SpecimenType,FrozenAtSite,SpecimenCustomDataValues.SpecimenCustomDataKey',
        });

        const [{ data: collectedBy }, { data: frozenBy }, { data: processedBy }] = await Promise.all([
          spec.collectedBy ? getUser(spec.collectedBy) : { data: null },
          spec.frozenBy ? getUser(spec.frozenBy) : { data: null },
          spec.processedBy ? getUser(spec.processedBy) : { data: null },
        ]);

        const initialSelectedState: CollectDepositSelectedStates = {
          animal: spec.animal,
          collectedBy: collectedBy ? { name: `${collectedBy.firstName} ${collectedBy.lastName}`, value: collectedBy } : undefined,
          freezeDate: new Date(spec.freezeDate),
          frozenBy: frozenBy ? { name: `${frozenBy.firstName} ${frozenBy.lastName}`, value: frozenBy } : undefined,
          freezeLocation: spec.frozenAtSite
            ? { name: spec.frozenAtSite.name, value: spec.frozenAtSite }
            : { name: '', value: {} as StorageSite },
          notes: spec.notes,
          processedBy: processedBy ? { name: `${processedBy.firstName} ${processedBy.lastName}`, value: processedBy } : undefined,
          qualityPercentage: spec.qualityPercentage,
          qualityType: { name: spec.quality, value: spec.quality },
          quantity: { value: spec.originalQuantity.toString(), valid: true },
          specimenUOM: { name: spec.specimenUOM, value: spec.specimenUOM },
          specimensType: { name: spec.specimenType?.name ?? '', value: spec.specimenType! },
          specimenCustomDataValues: spec.specimenCustomDataValues,
        };

        if (spec.specimenCustomDataValues.length === 0) {
          getCustomDataPoints();
        }

        setInitialSpecimenType({ name: spec.specimenType?.name ?? '', value: spec.specimenType! });
        setSelectedStates(initialSelectedState);
      }
    } catch (error) {
      showToast.error(toastMessages.FAILED_TO_RETRIEVE_SPECIMEN);
    } finally {
      setIsLoadingCustomDataPoints(false);
    }
  };

  const getCustomDataPoints = async () => {
    let customDataArray: SpecimenCustomDataValue[] = [];
    const { data: specimenCustomData } = await getCustomDataKeys({
      filter: `specimenTypeId eq ${selectedStates.specimensType?.value.specimenTypeId}`,
      include: 'SpecimenType',
    });

    customDataArray = specimenCustomData.map((data: SpecimenCustomDataKey) => {
      return { specimenCustomDataKeyId: data.specimenCustomDataKeyId, specimenCustomDataKey: data, value: '' };
    });

    setSelectedStates(prevStates => ({ ...prevStates, specimenCustomDataValues: customDataArray }));
  };

  useEffect(() => {
    if (initialSpecimenType && initialSpecimenType.value.specimenTypeId != selectedStates.specimensType?.value.specimenTypeId) {
      getCustomDataPoints();
    } else {
      getSpec();
    }
  }, [selectedStates.specimensType?.value.specimenTypeId]);

  const handleUpdate = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!updatingSpecimen) return;
    setUpdatingSpecimen(false);
    cleanCustomData(selectedStates.specimenCustomDataValues);

    try {
      const payload = {
        specimenUOM: selectedStates.specimenUOM?.value!,
        quality: selectedStates.qualityType?.value,
        qualityPercentage: selectedStates.qualityPercentage,
        freezeDate: selectedStates.freezeDate!,
        collectedBy: selectedStates.collectedBy?.value.userId,
        processedBy: selectedStates.processedBy?.value.userId,
        frozenBy: selectedStates.frozenBy?.value.userId,
        frozenAtSiteId: selectedStates.freezeLocation?.value.storageSiteId,
        specimenTypeId: selectedStates.specimensType?.value.specimenTypeId!,
        notes: selectedStates.notes,
        specimenCustomDataValues: selectedStates.specimenCustomDataValues,
      };

      await updateSpecimen(+specimenId!, payload);
      showToast.success(toastMessages.SPECIMEN_UPDATE_SUCCESS);
    } catch (error) {
      showToast.error(toastMessages.SPECIMEN_UPDATE_FAIL);
    } finally {
      setUpdatingSpecimen(true);
      handleCancel();
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    getSpec();
  };

  const checkValidity = () => {
    return selectedStates.specimenCustomDataValues?.every(customData => {
      if (customData.specimenCustomDataKey?.required) {
        return customData.value != undefined && customData.value?.length > 0;
      } else {
        return true;
      }
    });
  };

  return (
    <div className="specimen-view-edit">
      {itemId === undefined && <BackButton />}
      {isLoading || isLoadingCustomDataPoints ? (
        <Loader loaderSize="medium" pageLoader />
      ) : (
        <form action="submit" onSubmit={e => handleUpdate(e)}>
          <div className="inventory-action card">
            <h2>{INVENTORY_CONSTANTS.ITEM_DETAILS}</h2>
            <TransactionLabelField label={`${INVENTORY_CONSTANTS.LOT_NUMBER}:`} value={<label>{specimenId}</label>} />
            <TransactionLabelField
              label={`${INVENTORY_CONSTANTS.ANIMAL}:`}
              value={
                <label>
                  {selectedStates.animal?.code}-{selectedStates.animal?.name}
                </label>
              }
            />
            <div className="form-row specimen-owners-container">
              <label>{INVENTORY_CONSTANTS.ACCOUNT_OWNERS} :</label>
              <div className="input-container specimen-owners">
                {specimenOwners?.map(owner => {
                  return (
                    <div className="specimen-owner">
                      <label className="account-name">{owner.account.name} </label>
                      <label className="specimen-quantity"> {`- ${owner.quantityAvailable} Qty`}</label>
                    </div>
                  );
                })}
              </div>
            </div>
            <TransactionLabelField
              label={`*${INVENTORY_CONSTANTS.ITEM_TYPE}:`}
              value={
                isEditMode ? (
                  <FilterDropdown
                    placeholder="Select Item Type"
                    options={specimensType}
                    value={selectedStates?.specimensType ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, specimensType: option }))}
                    disabled={!isEditMode}
                  />
                ) : (
                  <label>{selectedStates?.specimensType?.name ?? 'N/A'}</label>
                )
              }
            />

            {Boolean(selectedStates.specimenCustomDataValues?.length) &&
              selectedStates.specimenCustomDataValues?.map((data: SpecimenCustomDataValue) => {
                if (data.specimenCustomDataKey) {
                  const { isNumeric, active, keyName, required, minValue, maxValue } = data.specimenCustomDataKey;

                  return (
                    <div className="custom-data form-row" key={keyName + '-' + data.specimenId}>
                      <label>
                        {required ? '*' : ''}
                        {keyName}:
                      </label>
                      <div className="input-container">
                        {isEditMode ? (
                          <ValidatedInput
                            type={`${isNumeric ? 'number' : 'text'}`}
                            placeholder={`${data.value ? data.value : isNumeric ? 'Numeric' : 'Text'}`}
                            label={''}
                            min={minValue}
                            max={maxValue}
                            setValidatedState={value =>
                              handleCustomDataStateChange(value, data, selectedStates.specimenCustomDataValues, setSelectedStates)
                            }
                            validators={!active ? [] : required ? [Validators.REQUIRED] : []}
                          />
                        ) : (
                          <label>{data.value}</label>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            <TransactionLabelField
              label={`${INVENTORY_CONSTANTS.ITEM_UNIT_MEASURE}:`}
              value={
                isEditMode ? (
                  <FilterDropdown
                    placeholder="Select UoM"
                    options={Object.values(specimenUOM).map(item => ({ name: item, value: item })) as FilterOption<specimenUOM>[]}
                    value={selectedStates?.specimenUOM ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, specimenUOM: option }))}
                    disabled={!isEditMode}
                  />
                ) : (
                  <label>{selectedStates?.specimenUOM?.name ?? 'N/A'}</label>
                )
              }
            />
            <TransactionLabelField
              label={`${INVENTORY_CONSTANTS.QUALITY}:`}
              value={
                isEditMode ? (
                  <FilterDropdown
                    placeholder="Quality Score"
                    disabled={!isEditMode}
                    options={Object.values(QualityType).map(item => ({ name: item, value: item })) as FilterOption<QualityType>[]}
                    value={selectedStates?.qualityType ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, qualityType: option }))}
                  />
                ) : (
                  <label>{selectedStates?.qualityType?.name ?? 'N/A'}</label>
                )
              }
            />
            <TransactionLabelField
              label={`${INVENTORY_CONSTANTS.QUALITY} %:`}
              value={
                isEditMode ? (
                  <ValidatedInput
                    setValidatedState={e =>
                      setSelectedStates(prevStates => ({
                        ...prevStates,
                        qualityPercentage: e.value.length > 0 && e.value !== '-' ? clampValue(0, 100, Number(e.value)) : null,
                      }))
                    }
                    placeholder={'Quality %'}
                    min={0}
                    max={100}
                    disabled={!isEditMode}
                    label={''}
                    validators={[]}
                    type="number"
                    validatedStateForAutoFill={{ value: (selectedStates?.qualityPercentage ?? '').toString(), valid: true }}
                  />
                ) : (
                  <label>{(selectedStates?.qualityPercentage ?? 'N/A').toString()}</label>
                )
              }
            />
            <TransactionLabelField
              label={`${INVENTORY_CONSTANTS.LOT_DATE} :`}
              value={
                isEditMode ? (
                  <DateInput
                    disabled={!isEditMode}
                    value={selectedStates?.freezeDate}
                    onChange={(date: Date) => setSelectedStates(prevStates => ({ ...prevStates, freezeDate: date }))}
                    maxDate={new Date()}
                  />
                ) : (
                  <label>{selectedStates?.freezeDate ? new Date(selectedStates?.freezeDate).toLocaleDateString() : 'N/A'}</label>
                )
              }
            />
            <TransactionLabelField
              label={`${INVENTORY_CONSTANTS.COLLECTION_SITE} :`}
              value={
                isEditMode ? (
                  <FilterDropdown
                    placeholder="Select Collection Site"
                    disabled={!isEditMode}
                    options={freezeLocations}
                    value={selectedStates?.freezeLocation ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, freezeLocation: option }))}
                  />
                ) : (
                  <label>{selectedStates?.freezeLocation?.name ?? 'N/A'}</label>
                )
              }
            />
            <TransactionLabelField
              label={`${INVENTORY_CONSTANTS.COLLECTED_BY} :`}
              value={
                isEditMode ? (
                  <FilterDropdown
                    disabled={!isEditMode}
                    placeholder="Select Employee"
                    options={rsgUsers}
                    value={selectedStates?.collectedBy ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, collectedBy: option }))}
                  />
                ) : (
                  <label>{selectedStates?.collectedBy?.name ?? 'N/A'}</label>
                )
              }
            />
            <TransactionLabelField
              label={`${INVENTORY_CONSTANTS.PROCESSED_BY} :`}
              value={
                isEditMode ? (
                  <FilterDropdown
                    disabled={!isEditMode}
                    placeholder="Select Employee"
                    options={rsgUsers}
                    value={selectedStates?.processedBy ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, processedBy: option }))}
                  />
                ) : (
                  <label>{selectedStates?.processedBy?.name ?? 'N/A'}</label>
                )
              }
            />
            <TransactionLabelField
              label={`${INVENTORY_CONSTANTS.FROZEN_BY} :`}
              value={
                isEditMode ? (
                  <FilterDropdown
                    placeholder="Select Employee"
                    disabled={!isEditMode}
                    options={rsgUsers}
                    value={selectedStates?.frozenBy ?? defaultFilterOption}
                    onChange={option => setSelectedStates(prevStates => ({ ...prevStates, frozenBy: option }))}
                  />
                ) : (
                  <label>{selectedStates?.frozenBy?.name ?? 'N/A'}</label>
                )
              }
            />
            <div className="form-row">
              <label>{INVENTORY_CONSTANTS.ORIGINAL_QUANTITY}:</label>
              <div className="input-container edit-specimen">
                <label>{selectedStates?.quantity?.value}</label>
                <button
                  className="icon button"
                  onClick={() => {
                    navigate(ROUTE_PATHS.APP_INVENTORY_MANAGEMENT_RECONCILE_ + specimenId.toString(), {
                      state: { returnLocation: currentLocation },
                    });
                  }}>
                  <EditSVG />
                </button>
              </div>
            </div>
            <div className="form-row expanded-text-area">
              <label htmlFor="notes">{LABEL_CONSTANTS.NOTES}:</label>
              {isEditMode ? (
                <textarea
                  id="notes"
                  name="notes"
                  placeholder="Notes"
                  onChange={event => setSelectedStates(prevStates => ({ ...prevStates, notes: event.target.value }))}
                  value={selectedStates?.notes ?? ''}
                  rows={4}
                  cols={50}
                  disabled={!isEditMode}
                />
              ) : (
                <pre>{selectedStates.notes}</pre>
              )}
            </div>
            <h2>{ANIMAL_CONSTANTS.STORAGE_LOCATIONS}</h2>
            <ListStorageLocations inventoryId={specimenId!} />
            <div className="flex-right submit-button">
              {isEditMode ? (
                <>
                  <button type="button" className="small blue inverted button" onClick={handleCancel}>
                    {BUTTON_CONSTANTS.CANCEL}
                  </button>
                  &emsp;
                  <button type="submit" className="small green button" disabled={!checkValidity()}>
                    {BUTTON_CONSTANTS.UPDATE}
                  </button>
                </>
              ) : (
                <button type="button" className="small blue button" onClick={() => setIsEditMode(true)}>
                  {BUTTON_CONSTANTS.EDIT}
                </button>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default SpecimenViewEdit;
